import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { NewInstance } from "features/instances/domain/entities/NewInstance";
import {
  Instance as InstanceModel,
  InstanceFeatureToggle,
} from "../models/Instance";

export class InvalidInstanceFormInput extends Error {
  public invalidInput: string[];

  constructor(invalidInput: string[]) {
    super();
    this.invalidInput = invalidInput;
  }
}

export class InstanceRepo {
  constructor(private authorizedAxiosInstance: AxiosInstance) {}

  public async getAll(): Promise<InstanceModel[]> {
    const instances = await this.authorizedAxiosInstance.get<InstanceModel[]>(
      "/private/instances"
    );

    return instances.data;
  }

  public async getById(id: string): Promise<InstanceModel> {
    const instance = await this.authorizedAxiosInstance.get<InstanceModel>(
      "/private/instances?id=" + id
    );

    return instance.data;
  }

  public async create(instance: NewInstance) {
    const formData = this.generateFormData(instance);

    return this.authorizedAxiosInstance
      .post("/private/instances", formData)
      .then((res) => res.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => {
            // every error except for file upload errors are located in the errors list
            if (e.response.headers.errors) {
              const [invalidInput] = JSON.parse(e.response.headers.errors);

              return new InvalidInstanceFormInput(
                Object.values(invalidInput.constraints)
              );
            } else {
              // file upload errors
              return new Error(e.response.data);
            }
          },
          "*": () => {
            return new Error(e.response.data);
          },
        });
      });
  }

  public async update(id: string, updatedInstance: NewInstance) {
    const formData = this.generateFormData(updatedInstance);

    return this.authorizedAxiosInstance
      .put("/private/instances/" + id, formData)
      .then((res) => res.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => {
            // every error except for file upload errors are located in the errors list
            if (e.response.headers.errors) {
              const [invalidInput] = JSON.parse(e.response.headers.errors);

              return new InvalidInstanceFormInput(
                Object.values(invalidInput.constraints)
              );
            } else {
              // file upload errors
              return new Error(e.response.data);
            }
          },
          "*": () => {
            return new Error(e.response.data);
          },
        });
      });
  }

  private generateFormData(instance: NewInstance): FormData {
    const formData = new FormData();

    formData.append("publicIdentifier", instance.publicIdentifier);
    formData.append("middlewareUrl", instance.middlewareUrl);
    formData.append("name", instance.name);
    formData.append("street", instance.street);
    formData.append("zipcode", instance.zipcode);
    formData.append("city", instance.city);
    formData.append("phone", instance.phone || "");
    formData.append("email", instance.email || "");
    formData.append("companyLogo", instance.companyLogo || "");
    formData.append("companyIcon", instance.companyIcon || "");
    formData.append("termsAndConditions", instance.termsAndConditions);
    formData.append("imprint", instance.imprint);
    formData.append("dataPrivacy", instance.dataPrivacy);
    formData.append("features", JSON.stringify(instance.features));
    formData.append("isPublished", instance.isPublished.toString());
    formData.append("hiddenB2C", instance.hiddenB2C.toString());
    return formData;
  }

  public async delete(id: string) {
    return this.authorizedAxiosInstance.delete("/private/instances/" + id);
  }

  public async getAvailableFeatures() {
    const { data } = await this.authorizedAxiosInstance.get<
      InstanceFeatureToggle[]
    >("/private/instances/features");

    return data;
  }
}
